export const kr = {
    wipers: {
        description: `
            특히 비, 진창으로 시야가 악화되거나 와이퍼 블레이드에 줄무늬가 생기기 시작하면 앞유리의 일부 영역을 건너뛰고 물 줄무늬를 남기면 와이퍼 블레이드를 교체할 때입니다.
            <br><br>
            AVANTECH 와이퍼 블레이드는 여름, 겨울 및 사계절 작동을 위해 설계된 모든 유형의 디자인으로 대표됩니다. 누구나 국산차와 국산차 모두에 적합한 모델을 선택할 수 있습니다.
        `,
        videos: [],
        content: [
            // {
            //     type: 'video',
            //     styles: {
            //         'margin-top': '5em',
            //         'margin-bottom': '12.5em'
            //     },
            //     videos: [
            //         {
            //             link: 'v7sxH7sGxfE',
            //             title: 'Виды щеток стеклоочистителя',
            //             description: 'Показываем какие бывают дворники, в чем их отличия и особенности каждого вида на примере дворников Avantech.'
            //         },
            //         {
            //             link: 'KS1Ul-C-2t8',
            //             title: 'Замена лезвий на щетках стеклоочистителя',
            //             description: 'В этом видео мы покажем, как сменить резиновые лезвия на каркасных, бескаркасных и гибридных щетках стеклоочистителя Avantech.'
            //         }
            //     ]
            // },
            {
                type: 'product',
                styles: {
                    'margin-top': '5em',
                    'margin-bottom': '5em',
                    'overflow': 'hidden'
                },
                title: '유니버셜 브러쉬<br><b>AERODYNAMIC</b>',
                image: 'wipers-aerodynamic-new.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 0,
                    top: -90,
                    right: -34,
                    scaleX: 1,
                    scaleY: 1 
                },
                description: '프레임(범용) 와이퍼 블레이드는 지난 수십 년간의 첨단 개발과 제조 경험을 결합합니다. 현대적 소재와 전통적인 디자인의 조합은 자동차 창문의 탁월한 청결성과 2~3시즌 동안의 안정적인 작동을 보장합니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '15em'
                },
                tables: [
                    {
                        title: 'AERODYNAMIC 와이퍼 브러시의 이점:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 180,
                            top: -16,
                            right: 82,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    '가격, 기능, 내구성의 최적의 균형을 이루었습니다.',
                                    '모든 프레임 요소의 이동성이 뛰어납니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '앞유리와의 밀착감으로 고속 주행 시에도 줄무늬가 생기지 않습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '부식 방지 코팅이 된 금속 본체.',
                                    '나노 그래파이트 코팅이 된 고무로 만든 고무 테이프입니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '다양한 크기(300~700mm)로 모든 차량에 맞는 브러시를 선택할 수 있습니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '12em'
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 0,
                    rotate: 66.4,
                    top: -100,
                    right: 80,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: '프레임 브러시<br><b>Smart NEO</b> & <br><b>Smart SR</b>',
                image: 'wipers-smart-neo-new.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'SMART NEO 와이퍼는 범용 장착 시스템 덕분에 일본, 한국, 중국, 미국, 유럽, 러시아에서 제조된 자동차의 95%와 호환됩니다. 첨단 제조 기술은 곡률에 관계없이 유리에 대한 와이퍼의 균일한 압력을 보장합니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '25em'
                },
                tables: [
                    {
                        title: 'SMART NEO & Smart SR 와이퍼 브러시의 장점:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 12,
                            right: 55,
                            scaleX: 0.6,
                            scaleY: 0.4 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    '모든 계절에 사용 가능합니다.',
                                    '좌측 운전석 및 우측 운전석 차량 모두에 호환됩니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '특수 고무 구성은 -50 °C까지의 온도에서도 효과적으로 작동합니다.',
                                    '유리에 브러시가 고르게 닿아 줄무늬가 나타나지 않습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '흑연으로 코팅된 고무는 조용한 작동과 뛰어난 습기 제거 기능을 제공합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '스포일러와 일체형 구조는 고속에서도 꼭 맞는 핏을 제공합니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '15em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 165.3,
                    top: -180,
                    right: -75,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: '겨울 브러시<br><b>SNOWGUARD,</b><br><b>SNOWGUARD plus</b> & <br><b>SNOWGUARD LEVEL UP</b>',
                image: 'wipers-snowguard-new.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'AVANTECH SNOWGUARD 겨울용 브러시는 AVANTECH 제품군에서 가장 인기 있는 시리즈 중 하나입니다. 이 브러시는 러시아 기후의 특성을 고려하여 특별히 제작되었으며 심한 서리, 눈보라, 강설과 같은 극한 조건에서 사용하기에 이상적입니다. 이러한 특성으로 인해 AVANTECH SNOWGUARD 브러시는 어려운 기상 조건에서도 탁월한 성능을 유지합니다.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '5em'
                },
                tables: [
                    {
                        title: 'SNOWGUARD 와이퍼 브러시의 이점:',
                        templates: [
                            {
                                collapse: 1,
                                pseudo: {
                                    type: 'mountains',
                                    index: 0,
                                    rotate: 0,
                                    top: 45,
                                    right: -305,
                                    scaleX: 1,
                                    scaleY: 1 
                                },
                                content: [
                                    '최대 -50 °C의 내동해성.',
                                    '프레임의 부식 방지 코팅.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '프레임은 눈과 물이 내부로 들어가는 것을 방지하는 고무 커버로 보호됩니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '프리미엄 등급 고무 테이프는 유리에 대한 견고한 접착력을 보장합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '다양한 크기(275~700mm)'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'description-box',
                split: false,
                half: true,
                styles: {
                    'margin-bottom': '22.5em'
                },
                templates: [
                    {
                        image: null,
                        title: '<b>SNOWGUARD</b>',
                        description: 'Hook mount for two types: 9x3 (standard) and 9x4 (wide).'
                    },
                    {
                        image: null,
                        title: '<b>SNOWGUARD PLUS</b> & <b>SNOWGUARD LEVEL UP</b>',
                        description: '중국 차량용 AERO 클립 마운트를 포함하여 10개의 커넥터가 포함되어 있습니다.'
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '13em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: -21.4,
                    top: -320,
                    right: -100,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: '<b>HYBRID PLUS</b><br>BRUSHES',
                image: 'wipers-hybrid-plus.png',
                description: '이 시리즈는 좌우 핸들 차량에 맞게 설계된 두 가지 유형의 윈드실드 와이퍼를 결합합니다. 하이브리드 와이퍼는 비대칭 공기 역학적 프레임을 가지고 있으므로 스티어링 휠 위치가 다른 차량은 적절한 와이퍼를 사용해야 한다는 점에 유의해야 합니다.'
            },
            {
                type: 'description-box',
                split: true,
                styles: {
                    'margin-bottom': '5em'
                },
                templates: [
                    {
                        image: 'wipers-right-hd.png',
                        title: 'Right hand drive',
                        description: '우측 핸들 차량용 AVANTECH HYBRID PLUS 와이퍼는 HR-16, HR-18 등의 제품 번호를 가지고 있습니다. 사이즈는 13개(300~700mm)입니다.'
                    },
                    {
                        image: 'wipers-left-hd.png',
                        title: 'Left hand drive',
                        description: '좌측 운전 차량용 AVANTECH HYBRID PLUS 와이퍼는 HL-16, HL-18 등의 제품 번호를 가지고 있습니다. 사이즈는 13개(300~700mm)입니다.'
                    }
                ]
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: '하이브리드 와이퍼 블레이드의 장점:',
                        pseudo: {
                            type: 'arrow',
                            index: 0,
                            rotate: 0,
                            top: 3,
                            right: 101,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    '최신 자동차에 완벽하게 어울리는 세련된 디자인입니다.',
                                    '브러시의 디자인과 생산은 OEM 표준을 충족합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '브러시의 고무 부분은 고품질이며 프레임에 단단히 고정되어 있습니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '브러시 프레임은 45%의 유리 섬유 함량을 가진 소재로 제작되어 내구성, 신뢰성 및 유리에 대한 균일한 압력을 보장합니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '특수 고무를 사용하여 브러시가 다양한 온도에서도 효과적으로 작동할 수 있습니다.',
                                    '브러시 커넥터는 9x3(표준) 및 9x4(광폭)의 두 가지 유형의 후크에 설치하는 데 적합합니다.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 90,
                    top: -180,
                    right: 115,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: '뒷창용 브러시<br><b>REAR</b> & <b>SNOWGUARD REAR</b>',
                image: 'wipers-smart-neo.png',
                description: ''
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                tables: [
                    {
                        title: '와이퍼 브러시 리어의 장점:',
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'AVANTECH REAR 브러시의 디자인은 원본과 비슷합니다.',
                                    '유리에 균일한 압력이 가해지기 때문에 줄무늬가 생길 위험이 줄어듭니다.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '고무의 흑연 코팅은 브러시의 조용한 작동과 내구성을 보장합니다.',
                                    '모든 종류의 인기 있는 플라스틱 브러시 마운트와 호환됩니다.'
                                ]
                            }
                        ]
                    },
                    {
                        title: '와이퍼 브러시의 SNOWGUARD REAR:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 85,
                            right: -2,
                            scaleX: 0.9,
                            scaleY: 0.9 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    '브러시의 고무 부분은 추위에 얼지 않는 특수한 부드러운 소재로 만들어졌으며, 유리를 완벽하게 청소합니다.',
                                    '프레임의 부식 방지 코팅.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    '프레임은 눈과 물이 내부로 들어가는 것을 방지하는 고무 커버로 보호됩니다.',
                                    '-50 °C까지의 내동해성.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    },
};