export const en = {
    wipers: {
        description: `
            If visibility has deteriorated, especially in rain, slush, or the wiper blades have begun to leave streaks, skip some areas of the windshield and leave streaks of water, then it's time to replace the wiper blades.
            <br><br>
            AVANTECH wiper blades are represented by all types of designs designed for summer, winter and all-season operation. Anyone can choose a suitable model for both foreign and domestic cars.
        `,
        videos: [],
        content: [
            // {
            //     type: 'video',
            //     styles: {
            //         'margin-top': '5em',
            //         'margin-bottom': '12.5em'
            //     },
            //     videos: [
            //         {
            //             link: 'v7sxH7sGxfE',
            //             title: 'Виды щеток стеклоочистителя',
            //             description: 'Показываем какие бывают дворники, в чем их отличия и особенности каждого вида на примере дворников Avantech.'
            //         },
            //         {
            //             link: 'KS1Ul-C-2t8',
            //             title: 'Замена лезвий на щетках стеклоочистителя',
            //             description: 'В этом видео мы покажем, как сменить резиновые лезвия на каркасных, бескаркасных и гибридных щетках стеклоочистителя Avantech.'
            //         }
            //     ]
            // },
            {
                type: 'product',
                styles: {
                    'margin-top': '5em',
                    'margin-bottom': '5em',
                    'overflow': 'hidden'
                },
                title: 'universal BRUSHES<br><b>AERODYNAMIC</b>',
                image: 'wipers-aerodynamic-new.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 0,
                    top: -90,
                    right: -34,
                    scaleX: 1,
                    scaleY: 1 
                },
                description: 'Frame (universal) windshield wiper blades combine advanced developments and manufacturing experience of the last decades. The combination of modern materials with traditional design ensures excellent cleanliness of car windows and reliable operation for 2-3 seasons.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '15em'
                },
                tables: [
                    {
                        title: 'Benefits of AERODYNAMIC WIPER BRUSHES:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 180,
                            top: -16,
                            right: 82,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Optimal balance between price, functionality and durability.',
                                    'Excellent mobility of all frame elements.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Tight contact with the windshield eliminates streaks even at high speed.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Metal body with anti-corrosion coating.',
                                    'Rubber tape made of rubber with Nano Graphite coating.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'A wide range of sizes (from 300 to 700 mm) allows you to select brushes for any car.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '12em'
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 0,
                    rotate: 66.4,
                    top: -100,
                    right: 80,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: 'FRAMED BRUSHES<br><b>Smart NEO</b> & <br><b>Smart SR</b>',
                image: 'wipers-smart-neo-new.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'SMART NEO wipers are compatible with 95% of cars manufactured in Japan, Korea, China, the USA, Europe and Russia, thanks to a universal mounting system. Advanced manufacturing technologies guarantee uniform pressure of the wiper to the glass, regardless of its curvature.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '25em'
                },
                tables: [
                    {
                        title: 'Advantages of SMART NEO & Smart SR WIPER BRUSHES:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 12,
                            right: 55,
                            scaleX: 0.6,
                            scaleY: 0.4 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Suitable for all-season use.',
                                    'Compatible for both left-hand drive and right-hand drive vehicles.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The special rubber composition works effectively at temperatures down to -50 °C.',
                                    'The even fit of the brush to the glass eliminates the appearance of streaks.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Graphite coated rubber provides quiet operation and excellent moisture removal.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The spoiler and one-piece construction provide a tight fit at high speed.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '15em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 165.3,
                    top: -150,
                    right: -75,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: 'WINTER BRUSHES<br><b>SNOWGUARD,</b><br><b>SNOWGUARD plus</b> & <br><b>SNOWGUARD LEVEL UP</b>',
                image: 'wipers-snowguard-new.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'AVANTECH SNOWGUARD winter brushes are one of the most popular series in the AVANTECH range. They are specially created taking into account the peculiarities of the Russian climate and are ideal for use in extreme conditions, such as severe frosts, blizzards and snowfalls. Due to these characteristics, AVANTECH SNOWGUARD brushes remain unsurpassed in difficult weather conditions.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '5em'
                },
                tables: [
                    {
                        title: 'Benefits of WIPER BRUSHES SNOWGUARD:',
                        templates: [
                            {
                                collapse: 1,
                                pseudo: {
                                    type: 'mountains',
                                    index: 0,
                                    rotate: 0,
                                    top: 45,
                                    right: -305,
                                    scaleX: 1,
                                    scaleY: 1 
                                },
                                content: [
                                    'Frost resistance up to -50 °C.',
                                    'Anti-corrosion coating of the frame.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The frame is protected by a rubber cover that prevents snow and water from getting inside.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'PREMIUM class rubber tape ensures tight adhesion to the glass.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Wide range of sizes (from 275 to 700 mm).'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'description-box',
                split: false,
                half: true,
                styles: {
                    'margin-bottom': '22.5em'
                },
                templates: [
                    {
                        image: null,
                        title: '<b>SNOWGUARD</b>',
                        description: 'Hook mount for two types: 9x3 (standard) and 9x4 (wide).'
                    },
                    {
                        image: null,
                        title: '<b>SNOWGUARD PLUS</b> & <b>SNOWGUARD LEVEL UP</b>',
                        description: '10 connectors included, including AERO Clip mount – for Chinese cars.'
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '13em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: -21.4,
                    top: -280,
                    right: -100,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: '<b>HYBRID PLUS</b><br>BRUSHES',
                image: 'wipers-hybrid-plus.png',
                description: 'The series combines two types of windshield wipers designed for left- and right-hand drive vehicles. It is worth noting that the hybrid wipers have an asymmetrical aerodynamic frame, so cars with different steering wheel positions require the use of appropriate wipers.'
            },
            {
                type: 'description-box',
                split: true,
                styles: {
                    'margin-bottom': '5em'
                },
                templates: [
                    {
                        image: 'wipers-right-hd.png',
                        title: 'Right hand drive',
                        description: 'AVANTECH HYBRID PLUS wipers for right-hand drive vehicles have article numbers such as HR-16, HR-18, etc. The number of sizes is 13 (from 300 to 700 mm).'
                    },
                    {
                        image: 'wipers-left-hd.png',
                        title: 'Left hand drive',
                        description: 'AVANTECH HYBRID PLUS wipers for left-hand drive vehicles have article numbers such as HL-16, HL-18, etc. The number of sizes is 13 (from 300 to 700 mm).'
                    }
                ]
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'ADVANTAGES OF HYBRID WIPER BLADES:',
                        pseudo: {
                            type: 'arrow',
                            index: 0,
                            rotate: 0,
                            top: 3,
                            right: 101,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Stylish design that fits perfectly with modern cars.',
                                    'The design and production of the brush meets OEM standards.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The rubber element of the brush is of high quality and is securely fixed in its frame.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The brush frame is made of a material with 45% fiberglass content, which ensures durability, reliability and uniform pressure on the glass.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Special rubber allows the brush to work effectively at different temperatures.',
                                    'The brush connector is suitable for installation on two types of hooks: 9x3 (standard) and 9x4 (wide).'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 90,
                    top: -180,
                    right: 115,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: 'BRUSHES FOR REAR WINDOW<br><b>REAR</b> & <b>SNOWGUARD REAR</b>',
                image: 'wipers-smart-neo.png',
                description: ''
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                tables: [
                    {
                        title: 'Advantages of WIPER BRUSHES REAR:',
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'The design of the AVANTECH REAR brush is similar to the original.',
                                    'Reduced risk of streaking due to uniform pressure on the glass.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The graphite coating of the rubber ensures silent operation of the brush and durability.',
                                    'Compatible with all popular types of plastic brush mounts.'
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Advantages of WIPER BRUSHES SNOWGUARD REAR:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 85,
                            right: -2,
                            scaleX: 0.9,
                            scaleY: 0.9 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'The rubber elements of the brush are made of a special soft material that does not freeze in the cold and cleans glass perfectly.',
                                    'Anti-corrosion coating of the frame.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'The frame is protected by a rubber cover that prevents snow and water from getting inside.',
                                    'Frost resistance down to -50 °C.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    },
};