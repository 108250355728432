export const ru = {
    wipers: {
        description: `
            Если ухудшился обзор, особенно в дождь, слякоть, или щетки стеклоочистителя стали оставлять разводы, пропускать некоторые области лобового стекла и оставлять полосы воды — значит, пришло время щетки заменить.
            <br><br>
            Щетки стеклоочистителя AVANTECH представлены всеми типами конструкций, рассчитанных на летнюю, зимнюю и всесезонную эксплуатацию. Любой желающий сможет подобрать подходящую модель как на зарубежный, так и отечественный автомобиль.
        `,
        videos: [],
        content: [
            {
                type: 'video',
                styles: {
                    'margin-top': '5em',
                    'margin-bottom': '12.5em'
                },
                videos: [
                    {
                        link: 'v7sxH7sGxfE',
                        title: 'Виды щеток стеклоочистителя',
                        description: 'Показываем какие бывают дворники, в чем их отличия и особенности каждого вида на примере дворников Avantech.'
                    },
                    {
                        link: 'KS1Ul-C-2t8',
                        title: 'Замена лезвий на щетках стеклоочистителя',
                        description: 'В этом видео мы покажем, как сменить резиновые лезвия на каркасных, бескаркасных и гибридных щетках стеклоочистителя Avantech.'
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'overflow': 'hidden'
                },
                title: 'УНИВЕРСАЛЬНЫЕ ЩЕТКИ<br><b>AERODYNAMIC</b>',
                image: 'wipers-aerodynamic-new.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 0,
                    top: -90,
                    right: -34,
                    scaleX: 1,
                    scaleY: 1 
                },
                description: 'Каркасные (универсальные) щетки стеклоочистителя объединяют в себе передовые разработки и опыт производства последних десятилетий. Сочетание современных материалов с традиционным дизайном обеспечивает отличную чистоту стекол автомобиля и надежную работу на протяжении 2-3 сезонов.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '15em'
                },
                tables: [
                    {
                        title: 'Преимущества ЩЕТОК СТЕКЛООЧИСТИТЕЛЯ AERODYNAMIC:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 180,
                            top: -16,
                            right: 82,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Оптимальное соотношению цены, функциональности и долговечности.',
                                    'Отличная подвижность всех элементов каркаса.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Плотный контакт с лобовым стеклом исключает разводы даже на высокой скорости.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Металлический корпус с антикоррозийным покрытием.',
                                    'Резинолента из каучука с нанесением покрытия Nano Graphite.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Широкий размерный ряд (от 300 до 700 мм) позволяет подобрать щетки на любой автомобиль.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '12em'
                },
                pseudo: {
                    type: 'arrows-circle',
                    index: 0,
                    rotate: 66.4,
                    top: -100,
                    right: 80,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: 'БЕСКАРКАСНЫЕ ЩЕТКИ<br><b>Smart NEO</b> и <br><b>Smart SR</b>',
                image: 'wipers-smart-neo-new.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'Щётки SMART NEO совместимы с 95% автомобилей, произведённых в Японии, Корее, Китае, США, Европе и России, благодаря универсальной системе креплений. Передовые технологии изготовления гарантируют равномерное прижатие щётки к стеклу, независимо от его изгиба.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '25em'
                },
                tables: [
                    {
                        title: 'Преимущества ЩЕТОК СТЕКЛООЧИСТИТЕЛЯ Smart NEO И Smart SR:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 13,
                            right: 56,
                            scaleX: 0.55,
                            scaleY: 0.55 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Подходят для всесезонного использования.',
                                    'Совместимы для<br> автомобилей как с левым,<br>так и с правым рулем.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Специальный состав резины работает эффективно при температуре до -50 °C.',
                                    'Равномерное прилегание щетки к стеклу исключает появление разводов.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Резина с графитовым покрытием обеспечивает бесшумность и отличное удаление влаги.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Спойлер и цельная конструкция обеспечивают плотное прилегание на высокой скорости.'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '15em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: 165.3,
                    top: -150,
                    right: -75,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: 'ЗИМНИЕ ЩЕТКИ<br><b>SNOWGUARD,</b><br><b>SNOWGUARD plus</b> и <br><b>SNOWGUARD LEVEL UP</b>',
                image: 'wipers-snowguard-new.png',
                imageStyles: {
                    'mix-blend-mode': 'multiply'
                },
                description: 'Зимние щетки AVANTECH SNOWGUARD — это одна из самых востребованных серий в ассортименте AVANTECH. Они специально созданы с учётом особенностей российского климата и идеально подходят для эксплуатации в экстремальных условиях, таких как сильные морозы, метели и снегопады. Благодаря этим характеристикам, щетки AVANTECH SNOWGUARD остаются непревзойденными в сложных погодных условиях.'
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '5em'
                },
                tables: [
                    {
                        title: 'Преимущества ЩЕТОК СТЕКЛООЧИСТИТЕЛЯ SNOWGUARD:',
                        templates: [
                            {
                                collapse: 1,
                                pseudo: {
                                    type: 'mountains',
                                    index: 0,
                                    rotate: 0,
                                    top: 45,
                                    right: -305,
                                    scaleX: 1,
                                    scaleY: 1 
                                },
                                content: [
                                    'Устойчивость к морозу до<br>-50 °C.',
                                    'Антикоррозийное покрытие каркаса.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Каркас защищен резиновым чехлом, предотвращающим попадание снега и воды внутрь.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Резинолента класса PREMIUM обеспечивает плотное прилегание к стеклу.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Широкий размерный ряд (от 275 до 700 мм).'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'description-box',
                split: false,
                half: true,
                styles: {
                    'margin-bottom': '22.5em'
                },
                templates: [
                    {
                        image: null,
                        title: '<b>SNOWGUARD</b>',
                        description: 'Крепление на крюк для двух типов: 9х3 (стандартный) и 9х4 (широкий).'
                    },
                    {
                        image: null,
                        title: '<b>SNOWGUARD PLUS</b> и <b>SNOWGUARD LEVEL UP</b>',
                        description: '10 коннекторов в комплекте, включая крепление AERO Clip – для китайских автомобилей.'
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em',
                    'padding-top': '13em'
                },
                pseudo: {
                    type: 'lines-through',
                    index: 0,
                    rotate: -21.4,
                    top: -280,
                    right: -100,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: 'ГИБРИДНЫЕ ЩЕТКИ<br><b>HYBRID PLUS</b>',
                image: 'wipers-hybrid-plus.png',
                description: 'Серия объединяет два вида щёток стеклоочистителей, предназначенных для автомобилей с левым и правым рулём. Стоит отметить, что у гибридных щёток аэродинамический каркас имеет асимметричную форму, поэтому для машин с разным расположением руля требуется использовать соответствующие щётки.'
            },
            {
                type: 'description-box',
                split: true,
                styles: {
                    'margin-bottom': '5em'
                },
                templates: [
                    {
                        image: 'wipers-right-hd.png',
                        title: 'Правое расположение руля',
                        description: 'Щетки AVANTECH HYBRID PLUS для автомобилей с правым расположением руля имеют артикулы типа HR-16, HR-18 и т.д. Количество размеров – 13 (от 300 до 700 мм).'
                    },
                    {
                        image: 'wipers-left-hd.png',
                        title: 'Левое расположение руля',
                        description: 'Щетки AVANTECH HYBRID PLUS для автомобилей с левым расположением руля имеют артикулы типа HL-16, HL-18 и т.д. Количество размеров – 13 (от 300 до 700 мм).'
                    }
                ]
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '12.5em'
                },
                tables: [
                    {
                        title: 'ПРЕИМУЩЕСТВА ГИБРИДНЫХ ЩЕТОК СТЕКЛООЧИСТИТЕЛЯ:',
                        pseudo: {
                            type: 'arrow',
                            index: 0,
                            rotate: 0,
                            top: 3,
                            right: 101,
                            scaleX: 1,
                            scaleY: 1 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Стильный дизайн, который отлично гармонирует с современными автомобилями.',
                                    'Конструкция и производство щетки соответствуют стандартам ОЕМ.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Резиновый элемент щетки отличается высоким качеством и надежно фиксируется в её каркасе.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Каркас щетки изготовлен из материала с 45% содержанием стекловолокна, что обеспечивает долговечность, надежность и равномерный прижим к стеклу.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Специальная резина позволяет щетке эффективно работать при различных температурах.',
                                    'Коннектор щетки подходит для установки на два типа крюков: 9х3 (стандартный) и 9х4 (широкий).'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                type: 'product',
                styles: {
                    'margin-bottom': '5em'
                },
                pseudo: {
                    type: 'sphere',
                    index: 0,
                    rotate: 90,
                    top: -180,
                    right: 115,
                    scaleX: 1,
                    scaleY: 1 
                },
                title: 'ЩЕТКИ ДЛЯ ЗАДНЕГО СТЕКЛА<br><b>REAR</b> и <b>SNOWGUARD REAR</b>',
                image: 'wipers-smart-neo.png',
                description: ''
            },
            {
                type: 'grid-table',
                styles: {
                    'margin-bottom': '7.5em'
                },
                tables: [
                    {
                        title: 'Преимущества ЩЕТОК СТЕКЛООЧИСТИТЕЛЯ REAR:',
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Конструкция щётки AVANTECH REAR аналогична оригинальной.',
                                    'Снижение риска появления разводов за счет равномерного давления на стекло.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Графитовое покрытие резинки обеспечивает бесшумную работу щетки и долговечность.',
                                    'Совместимость со всеми популярными типами креплений пластиковых щёток.'
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Преимущества ЩЕТОК СТЕКЛООЧИСТИТЕЛЯ SNOWGUARD REAR:',
                        pseudo: {
                            type: 'waves',
                            index: 0,
                            rotate: 0,
                            top: 85,
                            right: -2,
                            scaleX: 0.9,
                            scaleY: 0.9 
                        },
                        templates: [
                            {
                                collapse: 1,
                                content: [
                                    'Резиновые элементы щетки выполнены из специального мягкого материала, который не застывает на морозе и прекрасно очищает стекло.',
                                    'Антикоррозийное покрытие каркаса.'
                                ]
                            },
                            {
                                collapse: 1,
                                content: [
                                    'Каркас защищен резиновым чехлом, предотвращающим попадание снега и воды внутрь.',
                                    'Устойчивость к морозу до -50 °C.'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    },
};